.mobile-nav-container {
    width: 100%;
    padding: 11px 13px;
    /*bottom: 0;*/
    display: none;
    /*position: fixed;*/
}

.mobile-nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 25px 11px 25px;
    font-family: 'Manrope';
    background: linear-gradient(102.05deg, rgba(0, 191, 255, 0.15) -0.64%, rgba(0, 0, 0, 0) 98.13%);
    backdrop-filter: blur(20px);
    border-radius: 20px;
}

.mobile-nav ul {
    list-style: none;
    width: 100%;
    display: flex;
}

.mobile-nav ul li {
    /*width: 33.33%;*/
    width:50%;
    text-align: center;
}

.mobile-nav .nav-link {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: var(--white);
    text-decoration: none;
}

.mobile-nav .nav-link:hover {
    color: var(--borderBlue);
}

.mobile-selection {
    width: 100%;
    height: 5px;
    position: relative;
    margin-top: 9px;
}

.mobile-selection .mobile-selector {
    /*width: 33.33%;*/
    width:50%;
    height: 100%;
    position: absolute;
    background: var(--borderBlue);
    transition: all 0.5s ease-in-out;
}

@media screen and (max-width:600px) {
    .token-info{
        flex-direction: column;
        gap:10px;
    }
    .token-symbol{
        border-right: none;
        text-align: left;
    }
    .token-symbol > p.name-text{
        text-align: left;
    }
    .smart-contract{
        padding-left: 0;
    }
    .contract-key{
        font-size: 18px;
        overflow: visible;
        overflow: hidden;
        white-space: unset;
        word-break: break-all;
        overflow-wrap:break-word;
        word-wrap: break-word;
    }
}
@media only screen and (max-width: 815px) {
    .mobile-nav-container {
        display: block;
    }
}