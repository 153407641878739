.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.container {
    margin-bottom: 63px;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto 52px auto;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.mobile-nav {
    display: none;
}

a:hover,.social-network-container:hover, .social-networks:hover{
    cursor: pointer;
}



