.id-popup {
    width: 430px;
    max-width: 430px;
    padding: 20px 20px 15px 20px;
    border: 1px solid var(--borderBlue);
    position: absolute;
    right: 35px;
    bottom: -100px;
    word-wrap: break-word;
    background: #00233C;
    display: none;
}
.id-popup div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    color: var(--white);
}

.id-popup img {
    cursor: pointer;
    width: 12px;
}
.id-popup p {
    display: inline;
}

.id-popup h4 {
    color: var(--white);
}

.id-popup-active{
    display: block;

}