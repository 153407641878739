.landing-page {
    display: flex;
    gap: 20px;
    font-family: 'Manrope';
    padding: 0 70px;
}

.landing-page-left {
    width: 30%;
}

.landing-page-right {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.wallet-info-box {
    overflow: hidden;
}

.currencies {
    padding-bottom: 34px;
    border-bottom: 1px solid var(--deviderBlue);
    position: relative;
}

.arrow-down {
    position: absolute;
    bottom: -21px;
    left: 50%;
    transform: translateX(-50%);
    width: 41px;
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #142538;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
}

.arrow-down:hover {
    cursor: pointer;
    filter: drop-shadow(0px 0px 15px #33B6E1);
}

.arrow-down img {
    transition: all 0.5s ease-in-out;
}

.total-value-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 32px;
}

.total-value-container img {
    width: 80px;
}

.token-info-box {
    position: relative;
    padding-left: 160px;
}

.token-info-box .label {
    position: absolute;
    top: 0;
    left: 0;
}

.token-info {
    padding: 20px 27px 20px 0;
    display: flex;
    flex: 1;
}

.token-symbol {
    margin-left: 25px;
    padding-right: 58px;
    border-right: 1px solid var(--deviderBlue);
}

.token-symbol p {
    text-align: center;
}

.token-symbol h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: var(--white);
}

.token-symbol div {
    display: flex;
    align-items: center;
}

.token-symbol img {
    width: 30px;
    margin-right: 5px;
}

.smart-contract {
    font-size: 18px;
    padding-left: 25px;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    align-items: center;
}

.smart-contract p {
    margin-bottom: 3px;
    grid-area: 1/1/2/3;
}

.contract-key {
    margin-top: auto;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: var(--white);
    grid-area: 2/1/3/2;
}

.copy-to-clipboard {
    position: relative;
    grid-area: 2/2/3/3;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 1px solid var(--borderBlue);
    background: linear-gradient(102.05deg, rgba(0, 191, 255, 0.15) -0.64%, rgba(0, 0, 0, 0) 98.13%);
}

.copy-to-clipboard:hover {
    filter: drop-shadow(0px 0px 15px #33B6E1);
}

.copy-to-clipboard img {
    width: 15px;
}

.popup-msg {
    position: absolute;
    top: -40px;
    background: var(--borderBlue);
    color: #00233C;
    display: flex;
    align-items: center;
    padding: 7px 15px;
    border-radius: 20px;
    transition: opacity 1s ease-out;
    opacity: 0;
    overflow: hidden;
}

.copy-to-clipboard .active {
    opacity: 1;
    height: auto;
}

.popup-msg p {
    margin-right: 10px;
}

.market-data-box {
    overflow: hidden;
}

.label-and-currency-labels {
    display: flex;
    align-items: center;
    width: max-content;
    background: var(--textBlue);
    border-radius: 0px 0px 20px 0px;
}

.currency-labels {
    padding: 0 28px 0 15px;
}

.currency-labels p {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #00233C;
}

.market-data-info {
    padding: 0 19px 29px 29px;
    display: flex;
}

.market-data-info> :first-child {
    width: 100%;
}

.market-data-values {
    padding-top: 34px;
    border-right: 1px solid var(--deviderBlue);
    width: 33%;
}

.value-item span {
    font-family: 'Manrope';
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #1ACDAD;
}

.mb-35 {
    margin-bottom: 35px;
}

.market-data-chart {
    flex: 1;
}

.chart {
    width: 100%;
    height: 90%;
}

.chart-time {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    width: 82%;
}

.chart-time p {
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    color: #435779;
}

.total-value-locked-box {
    overflow: hidden;
}

.locked-values {
    padding-top: 34px;
    width: 33%;
    border-right: 1px solid var(--deviderBlue);
}

.total-value-locked-info {
    padding: 0 19px 29px 29px;
    display: flex;
}

.total-value-locked-chart {
    flex: 1;
    height: 300px;
}

.trading-container {
    margin-top: 20px;
}

.trading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px;
}

.trading-platform {
    display: flex;
    gap: 10px;
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.trading-logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.trading-logo>img {
    width: 25px;
    height: 25px;
}

@media only screen and (max-width: 1300px) {

    .token-info-box {
        padding-left: 120px;
    }

    .smart-contract {
        grid-template-columns: 120px auto;
        grid-template-rows: auto auto;
        grid-row-gap: 10px;
    }

    .smart-contract p {
        grid-area: 1/1/2/3;
        margin-bottom: 0;
    }

    .contract-key {
        grid-area: 2/1/3/3;
        margin-top: 0;
    }

    .copy-to-clipboard {
        grid-area: 1/2/2/3;
    }
}

@media only screen and (max-width: 1200px) {
    .landing-page {
        flex-direction: column;
    }

    .landing-page-left {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .wallet-info-box {
        width: 100%;
    }

    .landing-page-right,
    .token-info-box,
    .market-data-box,
    .total-value-locked-box {
        width: 100%;
    }
}

@media only screen and (max-width: 960px) {

    .landing-page {
        padding: 0 13px;
    }

    .wallet-info-box {
        width: 100%;
    }

    .token-info-box {
        flex-direction: column;
        padding-left: 0;
    }

    .token-info-box .label {
        position: static;
    }

    .token-info {
        padding: 32px 37px 47px 13px;
    }

    .token-symbol {
        margin: 0;
        padding-right: 18px;
    }

    .smart-contract {
        padding-left: 18px;
    }

    .contract-key {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .market-data-info {
        flex-direction: column;
        padding: 26px 16px 30px 16px;
    }

    .market-data-values {
        width: 100%;
        border-right: none;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 25px;
        padding-top: 0;
    }

    .market-data-values .value-item {
        margin-right: 20px;
    }

    .chart {
        height: 200px;
    }

    .chart-time {
        width: 92%;
    }

    .total-value-locked-info {
        flex-direction: column;
    }

    .locked-values {
        border: 0;
        width: 100%;
        margin-bottom: 25px;
    }
}