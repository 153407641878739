.tutorials {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    gap: 15px;
    color: white;
    /*color: #8bb0be;*/
    margin-bottom: 30px;
}

.arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #142538;
    flex-shrink: 0;
}

.arrow.rotate-left {
    rotate: 180deg;
}

.tutorial {
    padding: 10px 20px;
}

.tutorial-container {
    flex-grow: 1;
}

.tutorials.mobile {
    display: none;
}

.arrow:not(.disabled) {
    cursor: pointer;
}

.disabled {
    opacity: 0.4;
    cursor: none;
}

/** Mobile */
@media only screen and (max-width: 1000px) {
    .tutorials.desktop {
        display: none;
    }

    .tutorials.mobile {
        display: flex;
        flex-direction: column;
    }
}