.claim {
    font-family: 'Manrope';
    padding: 0 180px;
}

.wallet-information {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 40px;
}

/* .wallet-information div {
    display: flex;
    flex-direction: column;
    align-items: center;
} */

.mr-60 {
    margin-right: 60px;
}

.mb-20 {
    margin-bottom: 20px;
}

.info-text {
    margin-top: 13px;
    padding: 0 40px 27px 40px;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: var(--white);
}

.claim-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.round-info {
    padding: 30px 35px 35px 35px;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-template-rows: auto auto auto;
}

.claim-btn {
    cursor: pointer;
    height: 38px;
    width: 138px;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: var(--white);
    background: var(--borderBlue);
    border: none;
}

.round-closed {
    overflow: hidden;
    position: relative;
}

.closed-label {
    display: none;
}

.round-closed .round-info {
    opacity: 0.2;
    position: relative;
    z-index: -1;
}

.round-closed .round-label {
    display: flex;
    align-items: center;
    width: max-content;
    background: var(--textBlue);
    border-radius: 0px 0px 20px 0px;
    padding-right: 18px;
    position: relative;
    z-index: 1;
}

.round-closed .closed-label {
    display: block;
    margin-left: 22px;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #00233C;
}

.round-closed .closed-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 0;
}

.round-closed .label {
    position: relative;
    overflow: hidden;
}

.round-closed .label-circle {
    opacity: 0.2;
}

.round-closed .label p {
    opacity: 0.2;
}

.round-closed .claim-btn {
    opacity: 0.5;
}

.mobile-scroll {
    font-family: 'Montserrat, Semi-bold';
    width: 100%;
    overflow-x: hidden;
    display: none;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    cursor: grab;
    margin-bottom: 28px;
}

.mobile-scroll::-webkit-scrollbar {
    display: none;
}

.mobile-scroll p {
    white-space: nowrap;
    margin-right: 20px;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.02em;
    background-color: var(--borderBlue);
    background-image: linear-gradient(269.89deg, #9DE5FF -2.43%, var(--borderBlue) 98.9%);
    background-size: 100%;
    background-repeat: repeat;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;   
    opacity: 0.25;
}

.mobile-scroll .active {
    opacity: 1;
}
.mobile-scroll-new{
    display: none;
}
@media only screen and (max-width: 1200px) {

    .claim {
        padding: 0 70px;
    }
}

@media only screen and (max-width: 900px){
    .claim {
        padding: 0 15px;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto auto;
    }

    .mobile-scroll {
        grid-area: 1/1/2/2;
        display: flex;
    }
    .mobile-scroll-new{
        display: flex;
        flex-direction: column;
        gap:10px;
    }

    /*.wallet-information {
        grid-area: 3/ 1 /4 /2;
        flex-wrap: wrap;
        justify-content: unset;
    }*/
    .wallet-information {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        margin-bottom: 40px;
    }

    .wallet-information div {
        margin-top: 10px;
    }

    .claim-info {
        grid-area: 4/1/5/2;
    }

    .claim-grid {
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
    }

    .claim-grid-item {
        width: 100%;
    }

    .info-text {
        word-wrap: break-word;
    }

    .info-text p:first-child{
        margin-bottom: 15px;
    }
}
@media only screen and (max-width: 480px){
    .id-popup{
        width: 90%;
        padding: 15px;
        right: 25px;
        word-break: break-all;
    }
}
@media only screen and (max-width: 340px){
    .mobile-scroll-new .round-info{
        padding: 30px 10px 25px 15px;
    }
    .mobile-scroll-new .round-info > :first-child {
        margin-right:10px;
    }
}