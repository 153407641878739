@font-face {
  font-family: 'Manrope';
  src: url(./fonts/Manrope/Manrope-VariableFont_wght.ttf);
}

@font-face {
  font-family: 'Montserrat, Semi-bold';
  src: url(./fonts/Montserrat/Montserrat-SemiBold.ttf);
}

@font-face {
  font-family: 'Montserrat, Light';;
  src: url(./fonts/Montserrat/Montserrat-Light.ttf);
}

:root {
  --textBlue: #C1EAF8;
  --white: #FFF;
  --borderBlue: #33B6E1;
  --deviderBlue: rgba(51, 182, 225, 0.5);
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-image: url('./images/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.border-blue {
  border: 1px solid var(--borderBlue);
}

.p-30 {
  padding: 30px;
}
.br-20 {
  border-radius: 20px;
}

.prices-text {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: var(--white);
  font-family: 'Montserrat, Semi-bold';
}

.box-bg {
  backdrop-filter: blur(20px);
  background: linear-gradient(102.05deg, rgba(0, 191, 255, 0.15) -0.64%, rgba(0, 0, 0, 0) 98.13%);
}

.label {
  height: max-content;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  background: #00233C;
  width: max-content;
  border-radius: 20px 0px;
}

.label-circle {
  height: 14px;
  width: 14px;
  border: 1px solid var(--borderBlue);
  border-radius: 50%;
  margin-right: 10px;
}

.label p {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  background-color: var(--borderBlue);
  background-image: linear-gradient(269.89deg, #9DE5FF -2.43%, var(--borderBlue) 98.9%);
  background-size: 100%;
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;   
}

.name-text {
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: var(--textBlue);
  margin-bottom: 8px;
}

