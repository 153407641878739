
header {
    flex: 0 1 auto;
    height: 100px;
    border-bottom: 1px solid #144050;
    padding: 0 37px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Manrope';
    margin-bottom: 50px;
    background: linear-gradient(102.05deg, rgba(0, 191, 255, 0.15) -0.64%, rgba(0, 0, 0, 0) 98.13%);
    backdrop-filter: blur(20px);
    z-index: 1;
    position: relative;
}

.logo {
    width: 150px;
}

header nav {
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

header nav ul {
    list-style: none;
    width: 100%;
    display: flex;
}

header nav ul li {
    /*width: 33.33%;*/
    width:50%;
    text-align: center;
}

header nav .nav-link {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: var(--white);
    text-decoration: none;
}

header nav .nav-link:hover {
    color: var(--borderBlue);
}

.selection {
    width: 100%;
    height: 5px;
    position: absolute;
    bottom: 0;
}

.selection .selector {
    /*width: 33.33%;*/
    width:50%;
    height: 100%;
    position: absolute;
    background: var(--borderBlue);
    transition: all 0.5s ease-in-out;
}

.connect {
    display: flex;
    align-items: center;
}

.connect button {
    cursor: pointer;
    padding: 7px 20px;
    background: var(--borderBlue);
    margin-right: 10px;
    border: none;
    border-radius: 65px;
    color: var(--white);
    font-family: 'Manrope';
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
}

.connect .user-wallet-code {
    border-radius: 65px;
    background: linear-gradient(102.05deg, rgba(0, 191, 255, 0.15) -0.64%, rgba(0, 0, 0, 0) 98.13%);
    padding: 7px 20px;
    color: var(--white);
    width: 150px;
    height: 35px;
    cursor: pointer;
}

.connect .user-wallet-code p {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #C1EAF8;
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
    
}

@media only screen and (max-width: 1200px) {
    header nav {
        width: 40%;
    }
}

@media only screen and (max-width: 815px) {
    
    header {
        border-bottom: none;
        height: unset;
        padding: 14px 13px 5px 13px;
        background: unset;
    }
    
    header nav {
        display: none;
    }

    .logo {
        width: 108px;
        margin-top: 6px;
    }

    .connect button {
        padding: 7px 12px;
    }
}

@media only screen and (max-width: 390px) {
    header {
        flex-direction: column;
    }

    .logo {
        width: 130px;
        margin-bottom: 15px;
    }

    .connect {
        width: 100%;
        justify-content: space-between;
    }

    .connect button {
        flex-shrink: 1;
    }

    .user-wallet-code {
        flex-grow: 1;
    }
}